<template>
  <Nofind v-if="$loginEnv === 'wuhan'"></Nofind>
  <Welcome v-else></Welcome>
</template>

<script>
import Welcome from './welcome'
import Nofind from './404'
export default {
  data () {
    return {
    }
  },
  components: {
    Welcome,
    Nofind
  },
  created () {
  },
  mounted () {

  },
  computed: {},
  methods: {

  },
  watch: {}
}
</script>

<style scoped lang='scss'>
</style>
